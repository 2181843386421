import toast from "react-hot-toast"
import { setAllBanners, setSignInData, setToken } from "../../slices/authSlice"
import { deleteCookie, setObjectInCookie } from "../../utils/Utils"
import { authEndpoints } from "../api"
import { apiConnector } from "../apiConnector"


const { LOGIN_IN, LOGOUT_IN, SET_USER_IN, ADD_BANNER, REMOVE_BANNER, GET_ALL_BANNERS, EDIT_BANNER } = authEndpoints

export function login(userName, password, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", LOGIN_IN, { userName, password })

            if (response.data.success && response.data.userData) {
                setObjectInCookie('userData', response.data.userData, 7)
                setSignInData(response.data.userData)
                setToken(response.data.token)
                toast.success("Login Successful")
                window.location.reload()
                navigate(`/admin`)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function setUser(userName, email, password, role) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", SET_USER_IN, { userName, email, password, role })
            if (response.data.success) {
                toast.success(`${userName} Added Successfully`)

            } else {
                toast.error(`${userName} Already Exists`)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function logout(navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", LOGOUT_IN)

            if (response.data.success) {
                deleteCookie('userData')
                setSignInData({})
                setToken(null)
                localStorage.removeItem("tuf")
                toast.success("Logout Successful")
                window.location.reload()
                navigate(`/`)
            }
        } catch (error) {
            if (error.response.status === 401) {
                toast.error("Session Expired")
                deleteCookie('userData')
                setSignInData({})
                setToken(null)
                localStorage.removeItem("tuf")
                window.location.reload()
            }
        }
    }
}

export function addBaner(heading, description, hasIcon, icon, hasTargetDate, targetDate, availNowRoute, isActive, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", ADD_BANNER, { heading, description, hasIcon, icon, hasTargetDate, targetDate, availNowRoute, isActive })
            if (response.data.success) {
                if(setModalOpen) {
                    setModalOpen(false)
                }
                toast.success("Banner ADDED Successfully")
                dispatch(getAllBanners())
            }
        } catch (error) {
            console.log(error)
        }
    }
}
export function editBanner(heading, description, hasIcon, icon, hasTargetDate, targetDate, availNowRoute, isActive, setModalOpen, id) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", EDIT_BANNER, { heading, description, hasIcon, icon, hasTargetDate, targetDate, availNowRoute, isActive, id })
            if (response.data.success) {
                if(setModalOpen) {
                    setModalOpen(false)
                }
                toast.success("Banner EDITED Successfully")
                dispatch(getAllBanners())
            }
        } catch (error) {
            console.log(error)
        }
    }
}
export function getAllBanners() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_BANNERS)
            dispatch(setAllBanners(response.data.result))

        } catch (error) {
            console.log(error)
        }
    }
}
export function removeBanner(id) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", REMOVE_BANNER, { id })

            if (response.data.success) {
                toast.success("Banner Removed Successfully")
                dispatch(getAllBanners())
            }
        } catch (error) {
            console.log(error)
        }
    }
}