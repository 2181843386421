/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import Login from './pages/Login/Login'
import { Route, Routes } from 'react-router-dom'
import Drafts from './pages/ProblemSetter/Drafts/Drafts'
import Pending from './pages/ProblemSetter/Pending/Pending'
import Published from './pages/ProblemSetter/Published/Published'
import Preview from './pages/ProblemSetter/Preview/Preview'
import AddProblem from './pages/ProblemSetter/Problem/AddProblem'
import EditProblem from './pages/ProblemSetter/Problem/EditProblem'
import ProtectedRoute from './utils/ProtectedRoute'
import { useDispatch, useSelector } from 'react-redux'

import EditPending from './pages/ProblemSetter/Pending/EditPending'
import EditPublished from './pages/ProblemSetter/Published/EditPublished'
import AddUser from './pages/ProblemSetter/AddUser/AddUser'
import { getDrafts, getPending, getPublished } from './services/operations/problemSetterAPI'
import NotFound from './pages/NotFound/NotFound'
import Admin from './pages/Admin/Admin'
import SuccessStories from './pages/SuccessStories/SuccessStories'
import AddSuccess from './pages/SuccessStories/AddSuccess'
import Draft from './pages/BlogWrite/Draft/Draft'
import PreviewCourse from './pages/PremiumCourse/PreviewCourse/PreviewCourse'
import PublishedCourse from './pages/PremiumCourse/PublishedCourse/PublishedCourse'
import DraftCourse from './pages/PremiumCourse/DraftCourse/DraftCourse'
import AddCourse from './pages/PremiumCourse/AddCourse/AddCourse'
import UploadImage from './pages/UploadImage/UploadImage'
import BlogPending from './pages/BlogWrite/Pending/Pending'
import BlogPendingEdit from './pages/BlogWrite/Pending/EditPending'
import BlogPublished from './pages/BlogWrite/Published/Published'
import BlogPublishedEdit from './pages/BlogWrite/Published/EditPublished'
import BlogPreview from './pages/BlogWrite/Preview/Preview'
import AddBlog from './pages/BlogWrite/Blog/AddBlog'
import EditBlog from './pages/BlogWrite/Blog/EditBlog'
import { getBlogCategories } from './services/operations/blogs'
import AddTag from './pages/BlogWrite/AddTag/AddTag'
import AddDifficulty from './pages/AddDifficulty/AddDifficulty'
import VerifyCollege from './pages/VerifyCollege/VerifyCollege'
import Bugs from './pages/Bugs/Bugs'
import { getCookie } from './utils/Utils'
import PublishedPreview from './pages/BlogWrite/Preview/PublishedPreview'
import DBMS from './pages/CoreSubjects/DBMS/DBMS'
import OS from './pages/CoreSubjects/OS/OS'
import CN from './pages/CoreSubjects/CN/CN'
import AddBanner from './pages/AddBanner/AddBanner'

const App = () => {
    const { token } = useSelector((state) => state.auth)

    const dispatch = useDispatch();

    const { drafts, pending, published } = useSelector((state) => (state.problems))

    const { signinData } = useSelector((state) => (state.auth))
    const email = signinData?.email

    // useEffect(() => {
    //     console.log(getCookie('takeuforward_internal'))
    // }, [])
    // useEffect(() => {
    //     if (token) {
    //         if (!pending?.length || pending?.length) {
    //             dispatch(getPending(email))
    //         }
    //     }
    // }, [dispatch, pending?.length, email, token])

    // useEffect(() => {
    //     if (token) {
    //         if (!published?.length) {
    //             dispatch(getPublished(email))
    //         }
    //     }
    // }, [dispatch, published?.length, email, token])

    // useEffect(() => {
    //     if (token) {
    //         if (!drafts?.length) {
    //             dispatch(getDrafts(email));
    //         }
    //     }
    // }, [dispatch, drafts?.length, email, token])
    useEffect(() => {
        if (token) {
            // if (!drafts?.length) {
                dispatch(getBlogCategories());
            // }
        }
    }, [dispatch, email, token])

    return (
        <div>
            <Routes>
                <Route
                    path='/'
                    element={
                        // <ProtectedAuth>
                        <Login />
                        // </ProtectedAuth>
                    }
                />
                <Route element={<ProtectedRoute />}>
                    <Route
                        path='/admin'
                        element={
                            <Admin />
                        }
                    />
                    <Route
                        path='/write/draft'
                        element={
                            <Draft />
                        }
                    />
                    <Route
                        path='/write/add-blog'
                        element={
                            <AddBlog />
                        }
                    />
                    <Route
                        path='/write/add-tag'
                        element={
                            <AddTag />
                        }
                    />
                    <Route
                        path='/write/draft/:id/edit'
                        element={
                            <EditBlog />
                        }
                    />
                    <Route
                        path='/write/pending'
                        element={
                            <BlogPending />
                        }
                    />
                    <Route
                        path='/write/pending/:id/edit'
                        element={
                            <BlogPendingEdit />
                        }
                    />
                    <Route
                        path='/write/published'
                        element={
                            <BlogPublished />
                        }
                    />
                    <Route
                        path='/write/published/:id/edit'
                        element={
                            <BlogPublishedEdit />
                        }
                    />
                    <Route
                        path='/write/preview/:id'
                        element={
                            <BlogPreview />
                        }
                    />
                    <Route
                        path='/write/published-preview/:id'
                        element={
                            <PublishedPreview />
                        }
                    />
                    <Route
                        path='/success-stories'
                        element={
                            <SuccessStories />
                        }
                    />
                    <Route
                        path='/success-stories/add'
                        element={
                            <AddSuccess />
                        }
                    />
                    <Route
                        path='/problem-setter/draft'
                        element={
                            <Drafts />
                        }
                    />

                    <Route
                        path='/problem-setter/pending'
                        element={
                            <Pending />
                        }
                    />

                    <Route
                        path='/problem-setter/published'
                        element={
                            <Published />
                        }
                    />

                    <Route
                        path='/problem-setter/draft/add-problem'
                        element={
                            <AddProblem />
                        }
                    />
                    <Route
                        path='/problem-setter/draft/:id/edit'
                        element={
                            <EditProblem />
                        }
                    />
                    <Route
                        path='/problem-setter/pending/:id/edit'
                        element={
                            <EditPending />
                        }
                    />
                    <Route
                        path='/problem-setter/published/:id/edit'
                        element={
                            <EditPublished />
                        }
                    />

                    <Route
                        path='/problem-setter/draft/:id/preview'
                        element={
                            <Preview />
                        }
                    />
                    <Route
                        path='/problem-setter/pending/:id/preview'
                        element={
                            <Preview />
                        }
                    />
                    <Route
                        path='/premium-course/draft'
                        element={
                            <DraftCourse />
                        }
                    />
                    <Route
                        path='/premium-course/add-course'
                        element={
                            <AddCourse />
                        }
                    />
                    <Route
                        path='/premium-course/published'
                        element={
                            <PublishedCourse />
                        }
                    />
                    <Route
                        path='/premium-course/published/:id/preview'
                        element={
                            <PreviewCourse />
                        }
                    />
                    {signinData?.role === 'Admin' && (
                        <Route
                            path='/add-user'
                            element={
                                <AddUser />
                            }
                        />
                    )}
                    {signinData?.role === 'Admin' && (
                        <Route
                            path='/bugs'
                            element={
                                <Bugs />
                            }
                        />
                    )}
                    <Route
                        path='/upload-image'
                        element={
                            <UploadImage />
                        }
                    />
                    <Route
                        path='/add-difficulty'
                        element={
                            <AddDifficulty />
                        }
                    />
                    <Route
                        path='/dbms'
                        element={
                            <DBMS />
                        }
                    />
                    <Route
                        path='/os'
                        element={
                            <OS />
                        }
                    />
                    <Route
                        path='/cn'
                        element={
                            <CN />
                        }
                    />
                    <Route
                        path='/verify-college'
                        element={
                            <VerifyCollege />
                        }
                    />
                    <Route
                        path='/manage-banner'
                        element={
                            <AddBanner />
                        }
                    />
                </Route>
                <Route
                    path='*'
                    element={
                        <NotFound />
                    }
                />
            </Routes>
        </div>
    )
}

export default App