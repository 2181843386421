import { createSlice } from "@reduxjs/toolkit";
import { getCookie, getObjectFromCookie } from "../utils/Utils";

const initialState = {
    signinData: getObjectFromCookie("userData") ? getObjectFromCookie("userData") : {},
    loading: false,
    token: getCookie('takeuforward_internal') ? getCookie('takeuforward_internal') : null,
    allBanners: []
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setSignInData(state, value) {
            state.signinData = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
        setToken(state, value) {
            state.token = value.payload;
        },
        setAllBanners(state, value) {
            state.allBanners = value.payload;
        },
    },
});

export const { setSignInData, setLoading, setToken, setAllBanners } = authSlice.actions;

export default authSlice.reducer;
