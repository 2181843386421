import { BadgeCheck, Bug, Folder, FolderOpen, LayoutList, ListChecks, PencilLine, PlusCircle, Upload, UserRoundPlus } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';


function Sidebar({ sidebarOpen, setSidebarOpen }) {

    const { signinData } = useSelector((state) => (state.auth))
    const location = useLocation();
    const { pathname } = location;
    const trigger = useRef(null);
    const sidebar = useRef(null);

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!sidebar.current || !trigger.current) return;

            // Check if the click target is the button
            const isButtonClick = trigger.current.contains(target);

            if (!sidebarOpen || sidebar.current.contains(target) || isButtonClick) {
                // Prevent event propagation if the click is on the button
                if (isButtonClick) {
                    return;
                }
                setSidebarOpen(false);
            }
        };

        document.addEventListener('click', clickHandler);

        return () => document.removeEventListener('click', clickHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarOpen]);

    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded);
        if (sidebarExpanded) {
            document.querySelector('body').classList.add('sidebar-expanded');
        } else {
            document.querySelector('body').classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    const handleSideBar = () => {
        setSidebarOpen(!sidebarOpen)
    }

    // console.log(sidebarExpanded)
    return (
        <div>
            <div className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>
            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar lg:w-20 w-64  lg:sidebar-expanded:!w-64 2xl:!w-64 flex-shrink-0 bg-dark motion-reduce:transition-none dark:border-zinc-800 border-zinc-200 border-r p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
            >
                <div className="flex justify-between mb-10 pr-3 sm:px-2">
                    <button
                        ref={trigger}
                        className="lg:hidden text-gray-500 hover:text-gray-400"
                        onClick={() => handleSideBar()}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                    >
                        <span className="sr-only">Close sidebar</span>
                        <svg
                            className="w-6 h-6 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                        </svg>
                    </button>
                    <NavLink exact to="/admin" className="flex items-center gap-2">
                        <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="#D4162D" />
                            <path d="M3.27871 17.519H6.59052L5.26579 24.7618H8.24642L9.57114 17.519H13.2141L13.5453 15.8729H3.60989L3.27871 17.519Z" fill="white" />
                            <path d="M17.5195 15.8729H14.5389L13.2141 22.4572L15.2012 24.7618H23.4807L24.8055 15.8729H21.8248L20.5001 23.1157H16.8571L16.5259 22.4572L17.5195 15.8729Z" fill="white" />
                            <path d="M24.4743 24.7618L25.4678 18.1774L27.7861 15.8729H36.0656L35.4032 18.8358H32.7538V17.519H29.1108L28.1173 18.1774L27.7861 20.1527H35.0721V21.7988H27.4549L27.1237 24.7618H24.4743Z" fill="white" />
                        </svg>
                        <span className={`font-amaranth text-stone-100 font-bold text-3xl ${sidebarExpanded ? "block" : 'hidden 2xl:block'}`}>takeUforward</span>
                    </NavLink>
                </div>

                <div className="space-y-4">
                    {/* {signinData?.role === 'Admin' && (
                        <SidebarLinkGroup activecondition={pathname.includes('success-stories')}>
                            {(handleClick, open) => {
                                return (
                                    <React.Fragment>
                                        <a href="#0" className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('success-stories') && 'hover:text-gray-200  '}`} onClick={(e) => { e.preventDefault(); sidebarExpanded ? handleClick() : setSidebarExpanded(true) }}>
                                            <div className="flex items-center justify-between ">
                                                <div className="flex items-center ">
                                                    {open ? (
                                                        <FolderOpen className={`text-gray-400 ${pathname.includes('success-stories') && '!text-orange-600'}`} />
                                                    ) : (
                                                        <Folder className={`text-gray-400 ${pathname.includes('success-stories') && '!text-orange-600'}`} />
                                                    )}
                                                    <span className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('success-stories') ? 'text-brand font-bold ' : 'font-medium'}`}>Success Stories</span>
                                                </div>
                                                <div className="flex flex-shrink-0 ml-2">
                                                    <svg className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`} viewBox="0 0 12 12">
                                                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="lg:hidden  lg:sidebar-expanded:block 2xl:block">
                                            <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                                                <div className='py-2 flex relative'>
                                                    <div>
                                                        <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                                                            <NavLink exact to={`/success-stories`} className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('success-stories') && pathname.includes('success-stories') && 'hover:text-gray-200 font-bold'}`}>
                                                                <div className="flex items-center">
                                                                    <File size={20} className={`${pathname.includes(`success-stories`) && 'stroke-brand_50'}`} />
                                                                    <span className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`success-stories`) && 'text-brand_50'}`}>All Stories</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                        </SidebarLinkGroup>
                    )} */}
                    <SidebarLinkGroup activecondition={pathname.includes('problem-setter')}>
                        {(handleClick, open) => {
                            return (
                                <React.Fragment>
                                    <a href="#0" className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('problem-setter') && 'hover:text-gray-200  '}`} onClick={(e) => { e.preventDefault(); sidebarExpanded ? handleClick() : setSidebarExpanded(true) }}>
                                        <div className="flex items-center justify-between ">
                                            <div className="flex items-center ">
                                                {open ? (
                                                    <FolderOpen className={`text-gray-400 ${pathname.includes('problem-setter') && '!text-orange-600'}`} />
                                                ) : (
                                                    <Folder className={`text-gray-400 ${pathname.includes('problem-setter') && '!text-orange-600'}`} />
                                                )}
                                                <span className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('problem-setter') ? 'text-brand font-bold ' : 'font-medium'}`}>Problem Setter</span>
                                            </div>
                                            <div className="flex flex-shrink-0 ml-2">
                                                <svg className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`} viewBox="0 0 12 12">
                                                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="lg:hidden  lg:sidebar-expanded:block 2xl:block">
                                        <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                                            <div className='py-2 flex relative'>
                                                <div>
                                                    <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                                                        <NavLink exact to={`/problem-setter/draft`} className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('draft') && pathname.includes('problem-setter') && 'hover:text-gray-200 font-bold'}`}>
                                                            <div className="flex items-center">
                                                                <PencilLine className={`flex-shrink-0 h-5 w-5 ${pathname.includes('draft') && pathname.includes('problem-setter') && 'stroke-brand_50'}`} />
                                                                <span className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('draft') && pathname.includes('problem-setter') && 'text-brand_50'}`}>Draft</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>

                                                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0 `}>
                                                        <NavLink exact to="/problem-setter/pending" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('pending') && pathname.includes('problem-setter') && 'hover:text-gray-200'}`}>
                                                            <div className="flex items-center">
                                                                <LayoutList className={`flex-shrink-0 h-5 w-5 ${pathname.includes('pending') && pathname.includes('problem-setter') && 'stroke-brand_50'}`} />
                                                                <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('pending') && pathname.includes('problem-setter') && 'text-brand_50'}`}>Pending</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0 `}>
                                                        <NavLink exact to="/problem-setter/published" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('published') && pathname.includes('problem-setter') && 'hover:text-gray-200'}`}>
                                                            <div className="flex items-center">
                                                                <ListChecks className={`flex-shrink-0 h-5 w-5 ${pathname.includes('published') && pathname.includes('problem-setter') && 'stroke-brand_50'}`} />
                                                                <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('published') && pathname.includes('problem-setter') && 'text-brand_50'}`}>Published</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        }}
                    </SidebarLinkGroup>
                    {/* {signinData?.role === 'Admin' && (
                        <SidebarLinkGroup activecondition={pathname.includes('premium-course')}>
                            {(handleClick, open) => {
                                return (
                                    <React.Fragment>
                                        <a href="#0" className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('premium-course') && 'hover:text-gray-200  '}`} onClick={(e) => { e.preventDefault(); sidebarExpanded ? handleClick() : setSidebarExpanded(true) }}>
                                            <div className="flex items-center justify-between ">
                                                <div className="flex items-center ">
                                                    {open ? (
                                                        <FolderOpen className={`text-gray-400 ${pathname.includes('premium-course') && '!text-orange-600'}`} />
                                                    ) : (
                                                        <Folder className={`text-gray-400 ${pathname.includes('premium-course') && '!text-orange-600'}`} />
                                                    )}
                                                    <span className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('premium-course') ? 'text-brand font-bold ' : 'font-medium'}`}>Premium Course</span>
                                                </div>
                                                <div className="flex flex-shrink-0 ml-2">
                                                    <svg className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`} viewBox="0 0 12 12">
                                                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="lg:hidden  lg:sidebar-expanded:block 2xl:block">
                                            <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                                                <div className='py-2 flex relative'>
                                                    <div>
                                                        <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                                                            <NavLink exact to={`/premium-course/draft`} className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('draft') && pathname.includes('premium-course') && 'hover:text-gray-200 font-bold'}`}>
                                                                <div className="flex items-center">
                                                                    <PencilLine className={`flex-shrink-0 h-5 w-5 ${pathname.includes('draft') && pathname.includes('premium-course') && 'stroke-brand_50'}`} />
                                                                    <span className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('draft') && pathname.includes('premium-course') && 'text-brand_50'}`}>Draft</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                                                            <NavLink exact to="/premium-course/published" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('published') && pathname.includes('premium-course') && 'hover:text-gray-200'}`}>
                                                                <div className="flex items-center">
                                                                    <ListChecks className={`flex-shrink-0 h-5 w-5 ${pathname.includes('published') && pathname.includes('premium-course') && 'stroke-brand_50'}`} />
                                                                    <span className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('published') && pathname.includes('premium-course') && 'text-brand_50'}`}>Published</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                        </SidebarLinkGroup>
                    )} */}
                    <SidebarLinkGroup activecondition={pathname.includes('write')}>
                        {(handleClick, open) => {
                            return (
                                <React.Fragment>
                                    <a href="#0" className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('write') && 'hover:text-gray-200  '}`} onClick={(e) => { e.preventDefault(); sidebarExpanded ? handleClick() : setSidebarExpanded(true) }}>
                                        <div className="flex items-center justify-between ">
                                            <div className="flex items-center ">
                                                {open ? (
                                                    <FolderOpen className={`text-gray-400 ${pathname.includes('write') && '!text-orange-600'}`} />
                                                ) : (
                                                    <Folder className={`text-gray-400 ${pathname.includes('write') && '!text-orange-600'}`} />
                                                )}
                                                <span className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('write') ? 'text-brand font-bold ' : 'font-medium'}`}>Write Blogs</span>
                                            </div>
                                            <div className="flex flex-shrink-0 ml-2">
                                                <svg className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`} viewBox="0 0 12 12">
                                                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="lg:hidden  lg:sidebar-expanded:block 2xl:block">
                                        <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                                            <div className='py-2 flex relative'>
                                                <div>
                                                    <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                                                        <NavLink exact to={`/write/draft`} className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('draft') && pathname.includes('write') && 'hover:text-gray-200 font-bold'}`}>
                                                            <div className="flex items-center">
                                                                <PencilLine className={`flex-shrink-0 h-5 w-5 ${pathname.includes('draft') && pathname.includes('write') && 'stroke-brand_50'}`} />
                                                                <span className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('draft') && pathname.includes('write') && 'text-brand_50'}`}>Draft</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    {signinData?.role === 'Admin' && (
                                                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0 `}>
                                                            <NavLink exact to="/write/pending" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('pending') && pathname.includes('write') && 'hover:text-gray-200'}`}>
                                                                <div className="flex items-center">
                                                                    <LayoutList className={`flex-shrink-0 h-5 w-5 ${pathname.includes('pending') && pathname.includes('write') && 'stroke-brand_50'}`} />
                                                                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('pending') && pathname.includes('write') && 'text-brand_50'}`}>Pending</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )}
                                                    {signinData?.role === 'Admin' && (
                                                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0 `}>
                                                            <NavLink exact to="/write/add-tag" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('add-tag') && pathname.includes('write') && 'hover:text-gray-200'}`}>
                                                                <div className="flex items-center">
                                                                    <LayoutList className={`flex-shrink-0 h-5 w-5 ${pathname.includes('add-tag') && pathname.includes('write') && 'stroke-brand_50'}`} />
                                                                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('add-tag') && pathname.includes('write') && 'text-brand_50'}`}>Add New Category Tag</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )}
                                                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                                                        <NavLink exact to="/write/published" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('published') && pathname.includes('write') && 'hover:text-gray-200'}`}>
                                                            <div className="flex items-center">
                                                                <ListChecks className={`flex-shrink-0 h-5 w-5 ${pathname.includes('published') && pathname.includes('write') && 'stroke-brand_50'}`} />
                                                                <span className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('published') && pathname.includes('write') && 'text-brand_50'}`}>Published</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        }}
                    </SidebarLinkGroup>

                    {signinData?.role === 'Admin' && (
                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                            <NavLink exact to="/add-user" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('add-user') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                    <UserRoundPlus className={`flex-shrink-0 h-5 w-5 ${pathname.includes('add-user') && 'stroke-brand_50'}`}
                                    />
                                    <span
                                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('add-user') && 'text-brand_50'}`}>
                                        Add User
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                    )}
                    {signinData?.role === 'Admin' && (
                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                            <NavLink exact to="/bugs" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('bugs') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                    <Bug className={`flex-shrink-0 h-5 w-5 ${pathname.includes('bugs') && 'stroke-brand_50'}`} />
                                    <span
                                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('bugs') && 'text-brand_50'}`}>
                                        Bugs
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                    )}
                    {signinData?.role === 'Admin' && (
                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                            <NavLink exact to="/add-difficulty" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('add-difficulty') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                    <PlusCircle className={`flex-shrink-0 h-5 w-5 ${pathname.includes('add-difficulty') && 'stroke-brand_50'}`}
                                    />
                                    <span
                                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('add-difficulty') && 'text-brand_50'}`}>
                                        Add Difficulty
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                    )}
                    {signinData?.role === 'Admin' && (
                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                            <NavLink exact to="/cn" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('cn') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                    <PlusCircle className={`flex-shrink-0 h-5 w-5 ${pathname.includes('cn') && 'stroke-brand_50'}`}
                                    />
                                    <span
                                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('cn') && 'text-brand_50'}`}>
                                        CN
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                    )}
                    {signinData?.role === 'Admin' && (
                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                            <NavLink exact to="/dbms" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('dbms') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                    <PlusCircle className={`flex-shrink-0 h-5 w-5 ${pathname.includes('dbms') && 'stroke-brand_50'}`}
                                    />
                                    <span
                                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('dbms') && 'text-brand_50'}`}>
                                        DBMS
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                    )}
                    {signinData?.role === 'Admin' && (
                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                            <NavLink exact to="/os" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('os') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                    <PlusCircle className={`flex-shrink-0 h-5 w-5 ${pathname.includes('os') && 'stroke-brand_50'}`}
                                    />
                                    <span
                                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('os') && 'text-brand_50'}`}>
                                        OS
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                    )}
                    {signinData?.role === 'Admin' && (
                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                            <NavLink exact to="/verify-college" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('verify-college') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                    <BadgeCheck className={`flex-shrink-0 h-5 w-5 ${pathname.includes('verify-college') && 'stroke-brand_50'}`}
                                    />
                                    <span
                                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('verify-college') && 'text-brand_50'}`}>
                                        Verify Colleges
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                    )}
                    {signinData?.role === 'Admin' && (
                        <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                            <NavLink exact to="/manage-banner" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-banner') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                    <BadgeCheck className={`flex-shrink-0 h-5 w-5 ${pathname.includes('manage-banner') && 'stroke-brand_50'}`}
                                    />
                                    <span
                                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('manage-banner') && 'text-brand_50'}`}>
                                        Manage Banner
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                    )}

                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/upload-image" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('upload-image') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <Upload className={`flex-shrink-0 h-5 w-5 ${pathname.includes('upload-image') && 'stroke-brand_50'}`} />
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('upload-image') && 'text-brand_50'}`}>
                                    Upload Image
                                </span>
                            </div>
                        </NavLink>
                    </div>
                </div>

                {/* Expand / collapse button */}
                <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
                    <div className="px-3 py-2">
                        <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                            <span className="sr-only">Expand / collapse sidebar</span>
                            <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                                <path className="text-gray-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                                <path className="text-gray-600" d="M3 23H1V1h2z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;